import http from '@/http/axios.instance';
import tickservice from '@/tickservice.axios.instance';
import mapper from './unit.mapper';
import waffleFlagService from '@/services/waffleFlag.service';
import licenceService from '@/services/licence.service';
import { AwarenessUnitType } from '@/domain/awareness/enums/AwarenessUnitType.enum';
import { getUnixTime } from 'date-fns';
import { Unit } from '@/domain/units/Unit';
import { UnitLocation } from '@/domain/units/UnitLocation';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';

interface UnitLastLocation {
  altitude: number,
  distance: number,
  heading: number,
  lat: number,
  lon: number,
  parent_name: string,
  parent_uuid: string,
  timestamp: number,
  unit_ref_model_file_state: string,
  unit_type: UnitTypes,
  uuid: string,
  validity: Boolean,
  velocity: number
}

const fetchPaginated = async (baseUrl: string): Promise<UnitLastLocation[]> => {
  let result = [];
  const fetch = async (pageURL: string) => {
    let data = [];
    if (!pageURL) return data;
    const response = await http.get(pageURL);
    data = response.data.results;
    if (response.data.next) {
      const { search } = new URL(response.data.next);
      const nextUrl = baseUrl + search;
      data.concat(await fetch(nextUrl));
    }
    return data;
  };
  result = await fetch(baseUrl);
  return result;
};

const getLastUnitLocationsFromPyICT = async (project_uuid: string): Promise<UnitLastLocation[]> => {
  const url = `/api/accounts/v1/accounts/${project_uuid}/locations/`;
  const response = await fetchPaginated(url);
  return response;
};

const getLastUnitLocationsFromTickservice = async (projectId: string): Promise<UnitLastLocation[]> => {
  const url = `/projects/${projectId}/location/units`;
  const response = await tickservice.get(url);
  return response.data.map(location => mapper.mapTickserviceLocation(location));
};

const getUnits = async (project_uuid: string): Promise<{ units: Unit[]; czml: string; }> => {
  const unitsUrl = `/api/one/v1/projects/${project_uuid}/units/?skip_pagination`;
  const metadataUrl = `/api/accounts/v1/accounts/${project_uuid}/units/?skip_pagination`;

  const unitsResponse = await http.get(unitsUrl);
  const unitLocationsFromTickservice = waffleFlagService.unitLocationsFromTickService();
  const locationsResponse = unitLocationsFromTickservice ?
    await getLastUnitLocationsFromTickservice(project_uuid) :
    await getLastUnitLocationsFromPyICT(project_uuid);
  const metadataResponse = await http.get(metadataUrl);

  if (!licenceService.hasAwarenessLicence()) {
    unitsResponse.data = unitsResponse.data.filter(u => !Object.values(AwarenessUnitType).includes(u.type));
  }

  const units = unitsResponse.data.map((unit) => {
    const location = locationsResponse
      .find(unitLocation => unitLocation.parent_uuid === unit.uuid);
    const { metadata } = metadataResponse.data
      .find(unitMetadata => unitMetadata.uuid === unit.uuid);
    return { ...unit, location: { ...location }, metadata: { ...metadata } };
  });
  // todo: what do we do with units without location ?
  const mappedUnits = units.map(u => mapper.mapUnitToEntity(u));
  const czml = mapper.mapUnitCZML(mappedUnits.filter(m => Number.isFinite(m.lat) && Number.isFinite(m.lon)));
  return { units: mappedUnits, czml, };
};

const getUnitLocationHistoryCZMLfromPyICT = async (unitId: string, startTime: number | Date, stopTime: number | Date): Promise<UnitLocation> => {
  const url = `/api/ui2/v1/units/${unitId}/locations/?start=${getUnixTime(startTime)}&stop=${getUnixTime(stopTime)}&output=CZML`;
  const response = await http.get(url);
  return response.data;
};

/**
 * Get unit location history in CZML format for a given unit in the specified timespan.
 * @param projectId
 * @param unitId
 * @param startTime
 * @param stopTime
 * @returns {Promise<any>}
 */
const getUnitLocationHistoryCZMLfromTickservice = async (projectId: string, unitId: string, startTime: Date, stopTime: Date): Promise<UnitLocation> => {
  const url = `/projects/${projectId}/location/units/${unitId}?from_date=${startTime.toISOString()}&to_date=${stopTime.toISOString()}`;
  const response = await tickservice.get(url);
  return response.data;
};

/**
 * Gets the location history for a single Unit.
 * @param projectId - a valid uuid of a project
 * @param unitId - a valid uuid of a unit
 * @param startTime
 * @param stopTime
 * @returns {Promise<*>}
 */
const getUnitLocationHistory = async (projectId: string, unitId: string, startTime: Date, stopTime: Date): Promise<UnitLocation> => {
  const locationHistoryCZML = waffleFlagService.unitLocationsFromTickService() ?
    await getUnitLocationHistoryCZMLfromTickservice(projectId, unitId, startTime, stopTime) :
    await getUnitLocationHistoryCZMLfromPyICT(unitId, startTime, stopTime);
  return locationHistoryCZML;
};

const unitsApi = {
  getUnits,
  getUnitLocationHistory,
};
export default unitsApi;
