<template>
  <div class="cx-measured-point-panel" v-if="point">
    <!-- STATS -->
    <cx-stats-panel isAvatar>
      <template #avatar>
        <transition :name="applyAnimations('avatar')">
          <cx-avatar
            v-if="isAnimationReady"
            :source="avatar"
            :isReduced="isDetailsPanelReduced"
            :handleCameraFlyTo="handleCameraFlyToPoint"/>
        </transition>
      </template>
      <template #title>
        <!-- TITLE -->
        <transition :name="applyAnimations('title')">
          <cx-title-panel
            v-if="isAnimationReady"
            :title="point.name"
            :subtitle="point.subtitle.value"
            :subtitleIcon="point.subtitle.icon"
            :subtitleIconTooltip="point.subtitle.field"
            :isLoading="isFetchingData">
            <template #subtitle-slot>
              <span v-if="allowStabilization && point.pointCode"> - </span>
              <span v-if="allowStabilization && point.pointCode">{{ point.pointCode.value }}</span>
            </template>
          </cx-title-panel>
        </transition>
      </template>
      <template #relatedEntities>
        <transition :name="applyAnimations('relatedEntities')">
          <cx-related-entities-panel
            v-if="isAnimationReady"
            :showUnit="true"
            :unitName="unit"
            :showRefmodel="!restrictAll"
            :refmodelName="point.referenceModelName"/>
        </transition>
      </template>
      <template #mainMetadata>
        <transition :name="applyAnimations('mainMetadata')">
          <cx-main-data-panel
            v-if="isAnimationReady"
            :mainData="mainData"
            :class="{ nolicence: isDetailsPanelReduced }"/>
        </transition>
      </template>
      <template #secondaryMetadata>
        <transition :name="applyAnimations('mainMetadata')">
          <cx-secondary-data-panel
            v-if="isAnimationReady && isEditedAndDeletedMeasuredPointsEnabled"
            :secondaryData="secondaryData"
            :class="{ nolicence: isDetailsPanelReduced }"/>
        </transition>
      </template>
    </cx-stats-panel>
    <!-- CONTENT -->
    <div class="cx-cmn-dp__analysisPanel">
      <transition :name="applyAnimations('analysis')">
        <cx-metadata-panel
          v-show="isAnimationReady"
          :metadata="metadata"
          :class="{ nolicence: isDetailsPanelReduced, 'stabilization-licence': hasStabilizationLicence }"/>
      </transition>
      <div class="licence-container">
        <transition :name="applyAnimations('analysis')">
          <cx-licence-panel v-if="isDetailsPanelReduced"/>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import CxAvatar from '../common/CxAvatar';
import CxRelatedEntitiesPanel from '../common/CxRelatedEntitiesPanel';
import CxMainDataPanel from '@/app/detailsPanel/common/CxMainDataPanel/CxMainDataPanel';
import CxSecondaryDataPanel from '@/app/detailsPanel/common/CxSecondaryDataPanel';
import CxMetadataPanel from '../common/CxMetadataPanel';
import CxLicenceMixin from '@/mixins/CxLicence.mixin';
import CxOperationContextMixin from '@/mixins/CxOperationContext.mixin';
import CxContextLicenceMixin from '@/mixins/CxContextLicence.mixin';
import CxAnimationMixin from '@/mixins/CxAnimation.mixin';
import CxLicencePanel from '@/app/detailsPanel/common/CxLicencePanel';
import { PointDetailsViewModel } from './pointViewModel';
import { OperationContext } from '@/domain/OperationContext.enum';
import licenceService from '@/services/licence.service';
import CxStatsPanel from '../common/CxStatsPanel';
import CxTitlePanel from '../common/CxTitlePanel';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { ICONSolutionTypes } from '@/domain/ICONSolutionTypes.enum';
import CxWaffleFlagMixin from '@/mixins/CxWaffleFlag.mixin';

export default {
  name: 'CxMeasuredPointDetailsPanel',
  components: {
    CxAvatar,
    CxRelatedEntitiesPanel,
    CxMainDataPanel,
    CxSecondaryDataPanel,
    CxMetadataPanel,
    CxLicencePanel,
    CxStatsPanel,
    CxTitlePanel
  },
  mixins: [CxLicenceMixin, CxOperationContextMixin, CxContextLicenceMixin, CxAnimationMixin, CxWaffleFlagMixin],
  computed: {
    ...mapGetters('detailsPoint', ['selectedPoint', 'isFetchingData']),
    ...mapGetters('shell', ['isDetailsPanelReduced']),
    hasStabilizationLicence() {
      return licenceService.hasStabilizationLicence();
    },
    avatar() {
      if (this.selectedPoint.equipmentType === ICONSolutionTypes.EquipmentType) {
        if (this.selectedPoint.unitType === UnitTypes.ExcavatorType) {
          return 'avatars/excavator-points.svg';
        }
      }
      return 'avatars/mpoint.png';
    },
    point() {
      if (!this.selectedPoint) return null;
      return new PointDetailsViewModel(this.selectedPoint);
    },
    context() {
      return _.get(this.point, 'context');
    },
    unit() {
      if (!this.point) return '';
      return this.point.unitName;
    },
    mainData() {
      if (!this.point) return [];
      const { equipmentType, unitType, gnss, coordinateSystem, pointCode, lastUpdatedAt, logTime, jobName } = this.point;
      const result = [
        {
          field: jobName.field,
          value: jobName.value,
          icon: jobName.icon,
          showTooltip: true,
          tooltipText: jobName.field
        },
        {
          field: equipmentType.field,
          value: equipmentType.value,
          icon: equipmentType.icon,
          showTooltip: true,
          tooltipText: 'Device Type'
        },
        {
          field: unitType.field,
          value: unitType.value,
          icon: unitType.icon,
          showTooltip: true,
          tooltipText: unitType.field
        },
        {
          field: gnss.field,
          value: gnss.value,
          icon: gnss.icon,
          unit: gnss.unit,
          showTooltip: true,
          tooltipText: gnss.field
        },
        {
          field: coordinateSystem.field,
          value: coordinateSystem.value,
          icon: coordinateSystem.icon,
          showTooltip: true,
          tooltipText: coordinateSystem.field
        },
        {
          field: logTime.field,
          value: logTime.value,
          icon: logTime.icon,
          showTooltip: true,
          tooltipText: logTime.field
        }
      ];
      if (!lastUpdatedAt.value) {
        result.push({
          field: pointCode.fieldName,
          value: pointCode.value ? `${pointCode.fieldName}: ${pointCode.value}` : null,
          icon: pointCode.icon ?? ''
        });
      }

      return result;
    },
    secondaryData() {
      if (!this.point) return [];
      const { pointCode, lastUpdatedAt, comment, isDiscarded } = this.point;
      const result = [];

      if (lastUpdatedAt.value) {
        const edited = {
          field: lastUpdatedAt.field,
          value: lastUpdatedAt.value ? `${lastUpdatedAt.value} edited` : null,
          icon: lastUpdatedAt.icon,
          edited: [{
            field: pointCode.fieldName,
            value: pointCode.value ? pointCode.value : null,
            icon: pointCode.icon ?? '',
          },
          {
            field: comment.fieldName,
            value: comment.value ? comment.value : null,
            icon: comment.icon ?? '',
          }]
        };
        result.push(edited);

        if (isDiscarded.value) {
          result.push({
            field: isDiscarded.field,
            value: isDiscarded.value,
            icon: isDiscarded.icon,
          });
        }
      }

      return result;
    },
    metadata() {
      if (!this.point) return [];
      let result = [];
      // if the panel is small - display different order of fields
      if (!this.isDetailsPanelReduced) {
        result = [
          this.point.northing,
          this.point.easting,
          this.point.height,
          this.point.latitude,
          this.point.longitude,
          this.point.ellipsoidHeight
        ];
      } else {
        result = [
          this.point.northing,
          this.point.latitude,
          this.point.easting,
          this.point.longitude,
          this.point.height,
          this.point.ellipsoidHeight
        ];
      }
      // if we do have a stabilization licence we display additional fields here
      if (this.point.context === OperationContext.Stabilization && this.hasStabilizationLicence) {
        result = [
          ...result,
          this.point.drillPileStatus,
          this.point.heightOffsetType,
          this.point.heightOffset,
          this.point.sideDistance,
          this.point.targetHoleAngle,
          this.point.targetHoleHeading,
          this.point.station,
          this.point.actualToolAngle,
          this.point.actualToolHeading,
          this.point.toolDiameter,
          this.point.drillPileDepth,
          this.point.pileHeight,
          this.point.drillPileDepthSource,
          this.point.panelSerialNumber
        ];
      }
      return result;
    },

  },
  methods: {
    ...mapActions('detailsPoint', ['handleCameraFlyToPoint'])
  }
};

</script>

<style lang="less">
@import '../../../common';

.cx-measured-point-panel {
  height: 100%;
  background-color: @detailsContentBackgroundColor;
  display: flex;

  .cx-title-panel {
    .customIcon {
      vertical-align: middle;
    }
  }

  .cx-main-data-panel  {
    max-width: 200px;

    &.nolicence { max-height: @detailsNoLicenceHeight; }
  }

  .cx-metadata-panel.stabilization-licence {
    margin-top: @detailsTitleContentHeight - 111px;
    max-height: @detailsStabilizationMPointMetadataPanelHeight;
  }

  .licence-container {
    flex: 1 1 auto;

    .cx-licence-panel__icon {
      margin-top: -135px;
      flex: 1 1 auto;
    }
  }
}
</style>