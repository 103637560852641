import waffleFlagService from '@/services/waffleFlag.service';
import { AlarmTypeFilterValue } from '@/domain/awareness/enums/AlarmTypeFilterValue.enum';

const moneyPennyAlarmTypes = [
  AlarmTypeFilterValue.AvoidanceAlarm,
  AlarmTypeFilterValue.PersonAlarm,
  AlarmTypeFilterValue.UnclassifiedAlarm
];

// eslint-disable-next-line no-use-before-define
const awarenessServiceAlarmTypeFilter = <T extends Record<string, K>, K extends keyof T>(collection: T[], searchKey: K): T[] => {
  /**
   * When MoneyPenny waffle flag is not enabled, this function will filter given collection
   * by given search key and return only appropriate alarms.
   * - collection is a list of objects containing alarm type name in one of its keys;
   * - searchKey is the key under which the alarm type name value is found.
   * Example:
   *
   * Given a collection:
   * const values = [{id: 'TagAlarm', name: 'Tag Alarm'}, {id: 'PersonAlarm', name: 'Person Alarm'}]
   *
   * Usage of this function will look like this:
   * awarenessServiceAlarmTypeFilter(values, 'id');
   *
   * If MoneyPennyFlag is enabled, it will return:
   * >> [{id: 'TagAlarm', name: 'Tag Alarm'}, {id: 'PersonAlarm', name: 'Person Alarm'}]
   *
   * If it is not enabled:
   * >> [{id: 'TagAlarm', name: 'Tag Alarm'}]
   */
  if (!waffleFlagService.isMoneyPennyEnabled()) {
    return collection.filter((item) => !moneyPennyAlarmTypes.includes(item[searchKey] as AlarmTypeFilterValue));
  }
  return collection;

};

export default awarenessServiceAlarmTypeFilter;
