<template>
  <div class="cx-kpi-cards">
    <cx-selector :collection="timeframes" nameField="title" :value="selectedTimeframe" :onSelection="handleTimeframeSelection"/>
    <div class="cx-kpi-cards__container">
      <div v-for="card in cards" :key="card.title">
        <cx-kpi-card :icon="card.icon" :value="card.value" :title="card.title" :loading="card.loading" :error="card.error"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import awarenessServiceAlarmTypeFilter from '@/services/awareness.service';
import api from '@/http/api';
import datetimehelper from '@/utils/datetimehelper';
import CxKpiCard from './CxKpiCard.vue';
import CxSelector from '@/components/menuComponents/CxSelector';
import { DetectedZoneFilterValue } from '@/domain/awareness/enums/DetectedZoneFilterValue.enum';
import { AlarmTypeFilterValue } from '@/domain/awareness/enums/AlarmTypeFilterValue.enum';

const { TagAlarm, VehicleAlarm, PanicAlarm, Obstacle, Overspeed, LowBattery, CollisionAlarm, AvoidanceAlarm, PersonAlarm, UnclassifiedAlarm } = AlarmTypeFilterValue;
const { RedZone, YellowZone, GreenZone, CabinZone, NotInDangerZone, UndefinedZone } = DetectedZoneFilterValue;

export default {
  name: 'CxKpiCards',
  data() {

    const timeframes = [
      { title: this.$gettext('Yesterday'), ...datetimehelper.getYesterdayTimeRange() },
      { title: this.$gettext('Last Week'), ...datetimehelper.getLastWeekTimeRange() },
      { title: this.$gettext('Last Month'), ...datetimehelper.getLastMonthTimeRange() },
      { title: this.$gettext('This Month'), ...datetimehelper.getThisMonthTimeRange() }
    ];

    return {
      cards: [
        { title: this.$gettext('CRITICAL TAG ALARMS'), icon: 'cx_tag_alarm', loading: true, value: null, eventType: TagAlarm, detectedZones: [RedZone] },
        { title: this.$gettext('CRITICAL VEHICLE ALARMS'), icon: 'cx_vehicle_alarm ', loading: true, value: null, eventType: VehicleAlarm, detectedZones: [RedZone] },
        { title: this.$gettext('CRITICAL COLLISION ALARMS'), icon: 'cx_collision', loading: true, value: null, eventType: CollisionAlarm, detectedZones: [RedZone] },
        { title: this.$gettext('TAG PANIC ALARMS'), icon: 'cx_panic_alarm ', loading: true, value: null, eventType: PanicAlarm, detectedZones: [RedZone, YellowZone, GreenZone, CabinZone, NotInDangerZone, UndefinedZone] },
        { title: this.$gettext('OBSTACLE ALARMS'), icon: 'cx_obstacle', loading: true, value: null, eventType: Obstacle, detectedZones: [RedZone, YellowZone, GreenZone, CabinZone, NotInDangerZone, UndefinedZone] },
        { title: this.$gettext('LOW-BATTERY ALARMS'), icon: 'cx_low_battery', loading: true, value: null, eventType: LowBattery, detectedZones: [RedZone, YellowZone, GreenZone, CabinZone, NotInDangerZone, UndefinedZone] },
        { title: this.$gettext('OVERSPEED ALARMS'), icon: 'cx_overspeed', loading: true, value: null, eventType: Overspeed, detectedZones: [RedZone, YellowZone, GreenZone, CabinZone, NotInDangerZone, UndefinedZone] },
        { title: this.$gettext('AVOIDANCE ALARMS'), icon: 'mdi-brightness-1', loading: true, value: null, eventType: AvoidanceAlarm, detectedZones: [RedZone] },
        { title: this.$gettext('CRITICAL PERSON ALARMS'), icon: 'cx_tag_alarm', loading: true, value: null, eventType: PersonAlarm, detectedZones: [RedZone] },
        { title: this.$gettext('UNCLASSIFIED ALARMS'), icon: 'warning', loading: true, value: null, eventType: UnclassifiedAlarm, detectedZones: [RedZone] }
      ],
      timeframes,
      selectedTimeframe: timeframes[0],
    };
  },
  components: {
    CxKpiCard,
    CxSelector
  },
  computed: {
    ...mapGetters(['projectUuid'])
  },
  methods: {
    fetchKpiData(card) {
      return api.getKpiData({
        projectUuid: this.projectUuid,
        fromTime: this.selectedTimeframe.from.toISOString(),
        toTime: this.selectedTimeframe.to.toISOString(),
        eventTypes: [card.eventType],
        detectedZones: card.detectedZones
      });
    },
    setKpiData() {
      this.cards = awarenessServiceAlarmTypeFilter(this.cards, 'eventType');

      this.cards.forEach((c, index) => {
        this.fetchKpiData(c)
          .then(cardValue => {
            this.cards[index] = { ...c, value: cardValue, loading: false, error: false };
          })
          .catch(() => {
            this.cards[index] = { ...c, value: 'N/A', loading: false, error: true };

          });
      });
    },
    handleTimeframeSelection(date) {
      this.selectedTimeframe = date;
      this.setKpiData();
    }
  },
  mounted() {
    this.setKpiData();
  }
};

</script>

<style lang="less">
@import '../../../common';

.cx-kpi-cards {
  display: flex;
  flex-direction: column;

  .cx-kpi-cards__container {
    padding: 20px;
    max-width: 980px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .cx-selector {
    width: 250px;
    float: right;
    align-self: flex-end;
    margin-right: 20px;
  }
}

</style>
