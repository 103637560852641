import { round } from 'lodash';
import { getUnixTime, parseISO, isValid } from 'date-fns';
import CZML from '@/utils/czml/czml';
import { Packet } from '@/utils/czml/packet';
import { Model, Position, CxActivity } from '@/utils/czml/property';
import { CxProperties } from '@/utils/czml/cx-property';
import waffleFlagService from '@/services/waffleFlag.service';
import {
  metadataWarning,
  createUnit,
} from '@/domain/units/UnitFactory';
import { UnitTypes } from '@/domain/units/UnitTypes.enum';
import { Unit } from '@/domain/units/Unit';

interface MappedLocation {
  latitude?: number,
  longitude?: number,
  timestamp?: number,
  altitude?: number
}

interface MappedTickLocation extends MappedLocation {
  parent_uuid?: string
}

const mapCoord = (coordA?: number, coordB?: number) => {
  return Number.isFinite(coordA) ? coordA : coordB;
};

const mapDate = (isoDate: string): Date | null => {
  if (!isoDate) return null;
  const date = parseISO(isoDate);
  if (!isValid(date)) return null;
  return date;
};

const mapTickserviceLocation = (location: {latitude: number, longitude: number, timestamp: string, altitude?: number, unit_uuid: string}): MappedTickLocation => {
  if (!location) return {};
  return {
    latitude: location.latitude,
    longitude: location.longitude,
    timestamp: getUnixTime(parseISO(location.timestamp)),
    altitude: location.altitude || 0, // altitude is optional in tickservice
    parent_uuid: location.unit_uuid.replaceAll('-', '')
  };
};

const mapLocation = (location: {latitude: number, lat: number, lon: number, longitude: number, timestamp: number, altitude?: number}): MappedLocation => {
  if (!location) return {};
  if (waffleFlagService.unitLocationsFromTickService()) {
    return {
      latitude: location.latitude,
      longitude: location.longitude,
      timestamp: location.timestamp,
      altitude: location.altitude || 0
    };
  }
  return {
    latitude: location.lat,
    longitude: location.lon,
    timestamp: location.timestamp,
    altitude: location.altitude
  };

};

/**
   * @return an active reference model used by an unit. The output depends on equipment used.
   * Normally, this is a single active reference model name, some integrators return multiple, coma-concatenated values though.
   * In such an edge case, a first (primary) reference model is treated as an active one.
   * In a different case, 'No reference model selected' warning is returned by API instead of null value.
   */

const mapMetadata = (metadata: {reference: string, note: string}): {activeRefmodelName?: string | null, note?: string} => {
  const mapActiveRefmodelName = (reference: string) => {
    if (!reference || !reference.length) return null;
    if (reference.includes('No reference model selected')) return metadataWarning;
    if (reference.includes(',')) return reference.split(',')[0];
    return reference;
  };
  if (!metadata) return {};
  const { reference, note } = metadata;
  return {
    activeRefmodelName: mapActiveRefmodelName(reference),
    note
  };
};

const modelPath = {
  [UnitTypes.DozerType]: 'models/Dozer.gltf',
  [UnitTypes.GraderType]: 'models/Grader.gltf',
  [UnitTypes.ExcavatorType]: 'models/Excavator.gltf',
  [UnitTypes.ExcavatorWheeledType]: 'models/Excavator.gltf',
  [UnitTypes.BackhoeType]: 'models/Excavator.gltf',
  [UnitTypes.WheelLoaderType]: 'models/Loader.gltf',
  [UnitTypes.HaulTruckType]: 'models/DumpTruck.gltf',
  [UnitTypes.FieldCrewType]: 'models/Antenna_gps_60.gltf',
  [UnitTypes.BaseStationType]: 'models/Antenna_gps_60.gltf',
  [UnitTypes.DrillerType]: 'models/Driller.gltf',
  [UnitTypes.PilerType]: 'models/Piler.gltf',
  [UnitTypes.RollerType]: 'models/Roller.gltf',
  [UnitTypes.SnowGroomerType]: 'models/SnowGroomer.gltf',
  [UnitTypes.SlipformPaverType]: 'models/SlipformPaver.gltf',
  [UnitTypes.PlacerSpreaderType]: 'models/SlipformPaver.gltf',
  [UnitTypes.CurbAndGutterType]: 'models/CurbAndGutter.gltf',
  [UnitTypes.MillingType]: 'models/Miller.gltf',
  [UnitTypes.PaverType]: 'models/AsphaltPaver.gltf',
  [UnitTypes.TrimmerType]: 'models/Trimmer.gltf',
  [UnitTypes.Vehicle]: 'models/Pickup.gltf',
  [UnitTypes.CTLSkidSteer]: 'models/CTLSkidSteer.glb',
  [UnitTypes.DefaultUnitType]: 'models/Placemark.gltf',
  DEFAULT: 'models/Placemark.gltf'
};

const getUnitModelPath = (unitType: UnitTypes): string => {
  return modelPath[unitType] || modelPath.DEFAULT;
};

const mapUnit = ({ uuid, entityType, name, created_at, updated_at, lat, lon, location, metadata }) => {
  const { latitude, longitude, altitude, timestamp } = mapLocation(location);
  const { activeRefmodelName, note } = mapMetadata(metadata);
  return createUnit({
    uuid,
    entityType,
    name,
    createdAt: mapDate(created_at),
    updatedAt: mapDate(updated_at),
    lat: mapCoord(latitude, lat),
    lon: mapCoord(longitude, lon),
    altitude,
    timestamp,
    activeRefmodelName: activeRefmodelName || null,
    note
  });
};

const mapUnitToEntity = ({ uuid, name, created_at, updated_at, lat, lon, location, metadata, type }): Unit => {
  const typeMap = {
    BULLDOZER: () => mapUnit({ uuid, entityType: UnitTypes.DozerType, name, created_at, updated_at, lat, lon, location, metadata }),
    GRADER: () => mapUnit({ uuid, entityType: UnitTypes.GraderType, name, created_at, updated_at, lat, lon, location, metadata }),
    EXCAVATOR: () => mapUnit({ uuid, entityType: UnitTypes.ExcavatorType, name, created_at, updated_at, lat, lon, location, metadata }),
    'EXCAVATOR-WHEELED': () => mapUnit({ uuid, entityType: UnitTypes.ExcavatorWheeledType, name, created_at, updated_at, lat, lon, location, metadata }),
    BACKHOE: () => mapUnit({ uuid, entityType: UnitTypes.BackhoeType, name, created_at, updated_at, lat, lon, location, metadata }),
    'WHEEL-LOADER': () => mapUnit({ uuid, entityType: UnitTypes.WheelLoaderType, name, created_at, updated_at, lat, lon, location, metadata }),
    'DUMP-TRUCK': () => mapUnit({ uuid, entityType: UnitTypes.HaulTruckType, name, created_at, updated_at, lat, lon, location, metadata }),
    SURVEYOR: () => mapUnit({ uuid, entityType: UnitTypes.FieldCrewType, name, created_at, updated_at, lat, lon, location, metadata }),
    'Field Crew & iCON Solutions': () => mapUnit({ uuid, entityType: UnitTypes.FieldCrewType, name, created_at, updated_at, lat, lon, location, metadata }),
    'BASE-STATION': () => mapUnit({ uuid, entityType: UnitTypes.BaseStationType, name, created_at, updated_at, lat, lon, location, metadata }),
    DRILLER: () => mapUnit({ uuid, entityType: UnitTypes.DrillerType, name, created_at, updated_at, lat, lon, location, metadata }),
    PILER: () => mapUnit({ uuid, entityType: UnitTypes.PilerType, name, created_at, updated_at, lat, lon, location, metadata }),
    ROLLER: () => mapUnit({ uuid, entityType: UnitTypes.RollerType, name, created_at, updated_at, lat, lon, location, metadata }),
    'SNOW-GROOMER': () => mapUnit({ uuid, entityType: UnitTypes.SnowGroomerType, name, created_at, updated_at, lat, lon, location, metadata }),
    'SLIPFORM-PAVER': () => mapUnit({ uuid, entityType: UnitTypes.SlipformPaverType, name, created_at, updated_at, lat, lon, location, metadata }),
    'PLACER-SPREADER': () => mapUnit({ uuid, entityType: UnitTypes.PlacerSpreaderType, name, created_at, updated_at, lat, lon, location, metadata }),
    'CURB-AND-GUTTER': () => mapUnit({ uuid, entityType: UnitTypes.CurbAndGutterType, name, created_at, updated_at, lat, lon, location, metadata }),
    MILLING: () => mapUnit({ uuid, entityType: UnitTypes.MillingType, name, created_at, updated_at, lat, lon, location, metadata }),
    'ASPHALT-PAVER': () => mapUnit({ uuid, entityType: UnitTypes.PaverType, name, created_at, updated_at, lat, lon, location, metadata }),
    TRIMMER: () => mapUnit({ uuid, entityType: UnitTypes.TrimmerType, name, created_at, updated_at, lat, lon, location, metadata }),
    VEHICLE: () => mapUnit({ uuid, entityType: UnitTypes.Vehicle, name, created_at, updated_at, lat, lon, location, metadata }),
    'CTL-SKID-STEER': () => mapUnit({ uuid, entityType: UnitTypes.CTLSkidSteer, name, created_at, updated_at, lat, lon, location, metadata }),
  };
  const typeFactory = typeMap[type];
  return typeFactory ? typeFactory() : mapUnit({ uuid, entityType: UnitTypes.DefaultUnitType, name, created_at, updated_at, lat, lon, location, metadata });
};

const mapUnitTypeToEntityType = (type: string): UnitTypes => {
  const typeMap = {
    BULLDOZER: UnitTypes.DozerType,
    GRADER: UnitTypes.GraderType,
    EXCAVATOR: UnitTypes.ExcavatorType,
    'EXCAVATOR-WHEELED': UnitTypes.ExcavatorWheeledType,
    BACKHOE: UnitTypes.BackhoeType,
    'WHEEL-LOADER': UnitTypes.WheelLoaderType,
    'DUMP-TRUCK': UnitTypes.HaulTruckType,
    SURVEYOR: UnitTypes.FieldCrewType,
    'Field Crew & iCON Solutions': UnitTypes.FieldCrewType,
    'BASE-STATION': UnitTypes.BaseStationType,
    DRILLER: UnitTypes.DrillerType,
    PILER: UnitTypes.PilerType,
    ROLLER: UnitTypes.RollerType,
    'SNOW-GROOMER': UnitTypes.SnowGroomerType,
    'SLIPFORM-PAVER': UnitTypes.SlipformPaverType,
    'PLACER-SPREADER': UnitTypes.PlacerSpreaderType,
    'CURB-AND-GUTTER': UnitTypes.CurbAndGutterType,
    MILLING: UnitTypes.MillingType,
    'ASPHALT-PAVER': UnitTypes.PaverType,
    TRIMMER: UnitTypes.TrimmerType,
    'CTL-SKID-STEER': UnitTypes.CTLSkidSteer,
    VEHICLE: UnitTypes.Vehicle,
  };
  return typeMap[type] || UnitTypes.DefaultUnitType;
};

/**
 * Map Unit api data to CZML.
 * @param data - A collection of mapped unit data.
 * @returns {CZML}
 */
const mapUnitCZML = (data: Unit[]): string => {
  // TODO: this needs to be passed in a speparate CZML file
  // const clock = new Clock(
  //   '2018-09-10T00:01:00Z/2018-11-14T15:00:00Z',
  //   '2018-09-13T16:10:00Z',
  //   10
  // );
  const czml = new CZML('units', '1.0');
  data.filter(d => Number.isFinite(d.lon) && Number.isFinite(d.lat) && Number.isFinite(d.altitude)).forEach((d) => {
    const packet = new Packet(d.uuid, d.name);
    const position = new Position({
      epoch: undefined,
      interpolationAlgorithm: undefined,
      interpolationDegree: undefined,
      forwardExtrapolationType: undefined,
      forwardExtrapolationDuration: undefined,
      backwardExtrapolationType: undefined,
      backwardExtrapolationDuration: undefined
    });
    position.cartographicDegrees({ longitude: d.lon, latitude: d.lat, height: round(d.altitude as number, 0) });
    packet.assign(position);
    const model = new Model({
      show: true,
      gltf: getUnitModelPath(d.entityType),
      scale: 1,
      maximumScale: 1,
      minimumPixelSize: 128,
      incrementallyLoadTextures: false,
      heightReference: 'CLAMP_TO_GROUND',
      colorBlendAmount: '1',
      colorBlendMode: 'HIGHLIGHT',
      distanceDisplayCondition: undefined
      // silhouetteSize: 0,
      // silhouetteColor: 'GREENYELLOW'
    });
    packet.assign(model);
    const cxActivity = new CxActivity(d.timestamp);
    packet.assign(cxActivity);
    const cxProps = new CxProperties({ cxType: d.entityType, cxInitialPosition: position, cxActivity });
    packet.assign(cxProps);
    czml.addPacket(packet);
  });
  return czml.asCZML();
};


const unitMapper = {
  mapUnitToEntity,
  mapUnitTypeToEntityType,
  mapUnitCZML,
  mapTickserviceLocation
};
export default unitMapper;
